import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DetailService } from '@app/core/http/detail.service';
import { PredictionDetail } from '@app/shared/models/prediction';

@Injectable()
export class OtherDetailResolver implements Resolve<PredictionDetail> {

    constructor(private question: DetailService) {
    }

    resolve(route: ActivatedRouteSnapshot):
        Observable<PredictionDetail> {

        let detailName = route.data['detailName'];
        const studyPlanUrl = `${route.params['moduleName'].toLowerCase()}/${detailName}/${route.params['detailId']}`;

        return this.question.getDetail(studyPlanUrl);
    }
}