export class MockTest{
    //Common
    _id: string;
    name:String;
    status:String;
    mcqsingle:questionSub;
    mcqmultiple:questionSub;
  
    //Reading
    reorder:questionSub;
    fibdrag:questionSub;
    fibdrop:questionSub;
  
    //Listening
    dictation: questionSub
    fillInBlank: questionSub
    highlightIncorrectWord: questionSub
    selectMissingWord: questionSub
    highlightCorrectSummary: questionSub
    summarizeSpokenText: questionSub
  }
  
  export class questionSub {
    markDistribution: number;
    list: [];
  
    constructor(mark, list) {
      this.markDistribution = mark;
      this.list = list;
    }
    static create(mark,list) {
      return new questionSub(mark, list);
    }
  }

  export class PredictionFile{
    _id: string;
    name:String;
    questionType:String;
    questions:Array<string>;
  }

  export class User {
    userId: string;
    email: string;
    fullName: string;
    userType: string;
    gender: string;
    password: string;
    status: string;
    branch: string;
    file:any;
    desiredScore:any;
    package:any;
    mobilePhone:any;
  }
  