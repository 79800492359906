import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import { CommonService } from '@app/core/http/common.service';

@Injectable()
export class QuestionTypeResolver implements Resolve<[]> {

    constructor(private cservice: CommonService) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<[]> {

        const moduleName = route.paramMap.get("moduleName").toLowerCase();

        return this.cservice.getQuestionTypeListByName(moduleName);
    }
}

