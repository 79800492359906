import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TableFilter } from "@app/shared/models/table-filter";
import { MockTestList } from "@app/shared/models/mock-test-list";
import { TableListService } from "@app/core/http/table-list.service";
@Injectable()
export class FullMockTestResolver implements Resolve<MockTestList> {
  constructor(private service: TableListService) {}

  resolve(): Observable<MockTestList> {
    let filterMaker: TableFilter = {
      page: 1,
      limit: 10,
      sortColumn: "name",
      sortDirection: "asc",
    };

    return this.service.getMockTestList(filterMaker);
  }
}
