import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { QuestionService } from '@app/core/http/question.service';
import { CheckProgress } from '@app/shared/models/check-progress';

@Injectable()
export class OtherQuestionResolver implements Resolve<CheckProgress[]> {

    constructor(private question: QuestionService) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CheckProgress[]> {
            let type=route.params['type'] ? route.params['type'].toLowerCase() : route.data['type'].toLowerCase();
        return this.question.getPredictionInfo(type);
    }

}